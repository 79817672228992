import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";

const EditCMOverlay = ({ open, task, close, reload, cm_sites, ...props }) => {
  //console.log("hello task", task, props.filters);
  //console.log("hello user", props.session.user);
  //console.log("hello task", task);

  const submitSiteForm = (e) => {
    e.preventDefault();

    let that = this;

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    //data["user"] = props.user;
    data["user"] = props.session.user;

    /*var data = {
			site_plan_id : planid,
		};*/

    if (data["cm_end_date"] == "aaaa-mm-jj h:m:s") {
      data["cm_end_date"] = null;
    }

    if (data["cm_start_date"] == "aaaa-mm-jj h:m:s") {
      data["cm_start_date"] = task.cm_start_date;
    }

    //console.log("hello data", data);

    var alarmDate = new Date(data["cm_start_date"]);
    var alertingDate = new Date(task.task_created);
    var now = new Date();

    if (alarmDate > alertingDate) {
      alert("Attention, la date dalerting est antérieure à la date d'alarme!");
    } else if (alarmDate > now) {
      alert("Attention, la date d'alarme est postérieure à la date actuelle!");
    } else {
      props.isLoading({
        message: "Mise à jour du CM",
        animate: true,
      });

      api
        .post("/api/cm/task/edit", data)
        .then((response) => {
          //console.log("hello data", data);
          if (response.data.success) {
            /*props.setOverlay({
            message: "Tache CM modifié avec succés",
            label: "success",
            button: {
              type: "close",
              label: "OK",
              link: "javascript:void(0)",
            },
          });*/
            props.setFlash({
              active: true,
              message: "Tache CM modifié avec succés",
              classes: "success",
              style: {},
            });
          } else {
            props.setFlash({
              active: true,
              message: "Une erreur est survenue, Envoi echoué.",
              classes: "warning",
              style: {},
            });
          }
          close();
          props.doneLoading();
          reload();
        })
        .catch((err) => {
          alert(`Une erreur est survenue: ${err.message}`);
          props.doneLoading();
          props.setFlash({
            active: true,
            message: `Une erreur est survenue: ${err.message}`,
            classes: "warning",
            style: {},
          });
        });
    }
  };

  return (
    <div className={"ui-overlay " + (open ? " open" : "")}>
      <div className={"ui-overlay-content site-overlay col-md-5"}>
        <button
          data-package-id='0'
          onClick={(e) => {
            close(e);
          }}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5 className={"mb-3"}>Tâche CM</h5>

        <form onSubmit={submitSiteForm}>
          {open && (
            <>
              <input type='hidden' name='site_plan_id' value={task.key} />

              <input type='hidden' name='task_id' value={task.task_id} />

              {/*
                props.session.user.role == "admin"
                ?
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Tags</label>
                  <textarea
                    rows={2}
                    name="cm_tag"
                    className={"form-control col-md-8"}
                    value={task.cm_tag}
                  />
                </div>
                :
                null
            */}

              {props.session.user.role == "admin" ? (
                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>CM site</label>
                  <WindowedSelect
                    name={"cm_site_id"}
                    classNamePrefix={"custom-select"}
                    options={[...cm_sites]}
                    className={"col-md-8"}
                    defaultValue={{
                      value: task.task_site_logique_id,
                      label: task.task_site_logique_code,
                    }}
                  />
                </div>
              ) : null}

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Catégorie</label>
                <Select
                  name='cm_category'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: task.cm_category,
                    label: task.cm_category,
                  }}
                  options={props.filters.cm_category}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Alarme</label>
                <Select
                  name='cm_code'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: task.cm_code,
                    label: task.cm_code,
                  }}
                  options={props.filters.cm_code}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Type de problème</label>
                <Select
                  name='cm_problem_type'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: task.cm_problem_type,
                    label: task.cm_problem_type,
                  }}
                  options={props.filters.problem_types}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Priorité</label>
                <Select
                  name='cm_priority'
                  classNamePrefix={"custom-select"}
                  defaultValue={{
                    value: task.cm_priority,
                    label: task.cm_priority,
                  }}
                  options={props.filters.cm_priority}
                  className={"col-md-8"}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Référence CM parent</label>
                <input
                  type='text'
                  name='cm_parent_ref'
                  className={"form-control col-md-8"}
                  defaultValue={task.cm_parent_ref}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Détails problème</label>
                <input
                  type='text'
                  name='comment'
                  className={"form-control col-md-8"}
                  defaultValue={task.comment}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Sites impactés</label>
                <input
                  type='text'
                  name='cm_impacted_site_list'
                  className={"form-control col-md-8"}
                  defaultValue={task.cm_impacted_site_list}
                />
              </div>

              <div className='form-group custom-inline-form form-inline mt-3'>
                <label className={"font-exp"}>Secteurs impactés</label>
                <input
                  type='text'
                  name='cm_impacted_2g_sectors'
                  className={"form-control col-md-8"}
                  defaultValue={task.cm_impacted_2g_sectors}
                />
              </div>

              {/*<div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Secteurs 3G impactés</label>
                  <input
                    type="text"
                    name="cm_impacted_3g_sectors"
                    className={"form-control col-md-8"}
                    defaultValue={task.cm_impacted_3g_sectors}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Secteurs 4G impactés</label>
                  <input
                    type="text"
                    name="cm_impacted_4g_sectors"
                    className={"form-control col-md-8"}
                    defaultValue={task.cm_impacted_4g_sectors}
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Secteurs impactés</label>
                  <input
                    type="text"
                    name="cm_impacted_sector_list"
                    className={"form-control col-md-8"}
                    defaultValue={task.cm_impacted_sector_list}
                  />
                </div>

                <div className="form-group custom-inline-form mt-3 no-gutters">
                  <label className={"font-exp"}>Remarque</label>
                  <textarea
                    rows={5}
                    name="note"
                    className={"form-control"}
                  />
                  </div>*/}

              {props.session.user.role == "admin" ? (
                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Date alarme</label>
                  <input
                    defaultValue={
                      task.cm_start_date
                        ? task.cm_start_date
                        : "aaaa-mm-jj h:m:s"
                    }
                    name='cm_start_date'
                    type={"datetime"}
                    className={"form-control col-md-8"}
                  />
                </div>
              ) : null}

              {task.cm_state != null && props.session.user.role == "admin" ? (
                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Date fin</label>
                  <input
                    defaultValue={
                      task.cm_end_date ? task.cm_end_date : "aaaa-mm-jj h:m:s"
                    }
                    name='cm_end_date'
                    type={"datetime"}
                    className={"form-control col-md-8"}
                  />
                </div>
              ) : null}

              {task.cm_state != null && props.session.user.role == "admin" ? (
                <div className='form-group custom-inline-form form-inline mt-3'>
                  <label className={"font-exp"}>Solved by</label>
                  <Select
                    name='cm_solved_by'
                    classNamePrefix={"custom-select"}
                    defaultValue={{
                      value: task.solved_by_id,
                      label: task.solved_by,
                    }}
                    options={[
                      { label: "Sans intervention", value: "0" },
                      ...props.filters.services,
                    ]}
                    className={"col-md-8"}
                  />
                </div>
              ) : null}

              <div className='form-group custom-inline-form form-inline mt-3 no-gutters'>
                <input
                  type='submit'
                  className={"btn btn-primary col-md-12"}
                  value='Valider les modifications'
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditCMOverlay;
