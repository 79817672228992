import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  closeFlash,
  setFlash,
} from "../../Redux/actions/index";
import api from "../../Utils/Api";
import { connect } from "react-redux";
import Pagination from "../../Utils/Pagination";
import debounce from "lodash/debounce";
import Constants from "../../Utils/Constants";
import DeletePlanOverlay from "../Addresses/SiteList/DeletePlanOverlay";
import FiltersWrap from "../../Utils/Filter/FiltersWrap";
import FilterElement from "../../Utils/Filter/FilterElement";
import { buildFilters } from "../../Utils/Helpers";

const remote = Constants.apiURL; // process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'http://localhost/djezzy/api/public/';

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f)),
  };
}

class PlanningList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      managers: [],
      planning: [],
      showOverlay: false,
      current: 1,
      pages: 1,
      limit: 15,
      filters: {
        site: "",
        site_code: "",
        site_name: "",
        site_wilaya: "",
        site_cluster_manager: "",
        site_cluster: "",
        site_manager: "",
        site_owner: "",
        have_plan: false,
        not_have_plan: false,
        plan_start: "",
        plan_end: "",
      },
      showOverlayPlan: false,
      selected_site: 0,
      exportData: [],
      showOwnerOverlay: false,
      showDeletePlanOverlay: false,
      selected_plan: "",
    };

    props.isLoading({
      message: "Chargement du planning",
      animated: true,
    });

    this.loadSites = debounce(this.loadSites, 250);
  }

  changeDate = (name, event) => {
    console.log(event.target.value);
    const that = this;

    this.setState(
      {
        ["plan_" + name]: event.target.value,
      },
      function() {
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });
        that.loadSites(1);
      },
    );
  };

  changeLimit = (e) => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1,
      },
      () => this.loadSites(),
    );
  };

  selectPage = (p) => {
    this.props.isLoading({
      message: "Chargement de la page " + p,
      animated: true,
    });

    this.setState({ current: p });

    this.loadSites(p);
  };

  updateFilter = (event, name) => {
    let that = this;
    const value = event.target ? event.target.value : event.value;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        console.log(that.state.filters);
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });

        that.loadSites(1);
      },
    );
  };

  updateFilterCheck = (event, name) => {
    let that = this;
    const value = event.target ? event.target.checked : false;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        console.log(that.state.filters);
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });

        that.loadSites(1);
      },
    );
  };

  loadSites = (p = this.state.current) => {
    let that = this;

    const crits = buildFilters(this.state.filters);
    api
      .get(
        "/api/sites/fetch/filter?limit=" +
          this.state.limit +
          "&page=" +
          p +
          "&planning&" +
          crits,
      )
      .then(function(response) {
        if (response.data.success) {
          that.setState({
            sites: response.data.sites,
            pages: response.data.pages,
            total: response.data.total,
          });
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(false);
          }
          that.props.setFlash({
            active: true,
            message: response.data.error,
            classes: "warning",
            style: {},
          });
        }
        that.props.doneLoading();
      })
      .catch((error) => {
        this.props.setFlash({
          active: true,
          message: error.message,
          classes: "warning",
          style: {},
        });
        // console.log(`error:  ${error}`);
        this.props.doneLoading();
      });
  };

  componentDidMount() {
    this.loadSites();
  }

  toggleOverlay = (e) => {
    e.preventDefault();
    this.setState({ showOverlay: !this.state.showOverlay });
  };

  toggleOverlayPlan = (siteId) => {
    this.setState({
      selected_site: siteId,
      showOverlayPlan: !this.state.showOverlayPlan,
    });
  };

  editPlan = (e) => {
    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    const that = this;

    this.toggleOverlayPlan(0);
    this.props.isLoading({
      message: "Mise à jour du plan PM en cours",
      animated: true,
    });

    api.post("/api/planning/edit", data).then(function(response) {
      that.props.doneLoading();

      if (response.data.success) {
        that.loadSites(1);

        that.props.setFlash({
          active: true,
          message: "Nouveau plan PM inséré avec succés !",
          classes: "success",
          style: {},
        });
      } else {
        that.props.setFlash({
          active: true,
          message: "Insertion du PM plan echouée !",
          classes: "warning",
          style: {},
        });
      }
    });
  };

  exportResult = (e) => {
    e.preventDefault();

    const crits =
      "limit=-01" +
      "&cosite_type=" +
      this.state.filters.cosite_type +
      "&site_owner=" +
      this.state.filters.site_owner +
      "&site=" +
      this.state.filters.site +
      "&site_code=" +
      this.state.filters.site_code +
      "&site_name=" +
      this.state.filters.site_name +
      "&site_wilaya=" +
      this.state.filters.site_wilaya +
      "&plan_start=" +
      this.state.filters.plan_start +
      "&plan_end=" +
      this.state.filters.plan_end +
      "&site_manager=" +
      this.state.filters.site_manager +
      "&site_cluster_manager=" +
      this.state.filters.site_cluster_manager +
      "&site_cluster=" +
      this.state.filters.site_cluster +
      "&have_plan=" +
      this.state.filters.have_plan +
      "&not_have_plan=" +
      this.state.filters.not_have_plan;

    const action =
      remote +
      "/api/sites/fetch/filter?export=true&planning=true&token=" +
      this.props.user.token +
      "&" +
      crits;

    window.location = action;

    //e.target.submit();
  };

  toggleOwnerOverlay = (siteId) =>
    this.setState({
      selected_site: siteId,
      showOwnerOverlay: !this.state.showOwnerOverlay,
    });

  toggleDeleteOverlay = (siteId) =>
    this.setState({
      selected_site: siteId,
      showDeletePlanOverlay: !this.state.showDeletePlanOverlay,
    });

  render() {
    return (
      <React.Fragment>
        <DeletePlanOverlay
          open={this.state.showDeletePlanOverlay}
          toggle={this.toggleDeleteOverlay}
          site={this.state.selected_site}
          reload={this.loadSites}
          {...this.props}
        />

        <div
          className={this.state.showOverlay ? "ui-overlay open" : "ui-overlay"}
        >
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              data-package-id='0'
              onClick={this.toggleOverlay}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5>Insérer un nouveau fichier planning</h5>

            <form
              method='post'
              encType={"multipart/form-data"}
              action={Constants.apiURL + "/api/planning/new"}
            >
              <input type='hidden' name='token' value={this.props.user.token} />
              <div className='form-group mt-3'>
                <label className={"font-exp"}>
                  Selectionnez le fichier planning
                  <a
                    href={Constants.apiURL + "/template_planning.xlsx"}
                    className={"btn btn-sm btn-link"}
                    target='_blank'
                  >
                    {" "}
                    (voir template){" "}
                  </a>
                </label>

                <input
                  type='file'
                  id='planning_file'
                  name='planning_file'
                  className={"form-control"}
                />
                <Select
                  name={"planning_manager"}
                  placeholder={"Selectionnez le manager.."}
                  options={this.props.filters.managers}
                  className={"mt-2"}
                />

                <input
                  type='submit'
                  value='Envoyer'
                  className={"btn btn-primary btn-sm mt-5"}
                />
              </div>
            </form>
          </div>
        </div>

        <div
          className={
            this.state.showOverlayPlan ? "ui-overlay open" : "ui-overlay"
          }
        >
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              data-package-id='0'
              onClick={this.toggleOverlayPlan}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5>Ajouter un nouveau planning</h5>

            <form
              onSubmit={this.editPlan}
              method='post'
              encType={"multipart/form-data"}
              action='http://localhost/djezzy/public/api/planning/edit'
            >
              <input
                type='hidden'
                name='selected_site'
                value={this.state.selected_site}
              />
              <div className='form-group mt-3'>
                <label className={"font-exp"}>
                  Renseingez les information du planning
                </label>

                <input
                  type='date'
                  id='planning_date'
                  name='planning_date'
                  className={"form-control"}
                />

                <Select
                  inputId={"planning_manager"}
                  name={"planning_manager"}
                  placeholder={"Selectionnez le manager.."}
                  options={this.props.filters.managers}
                  className={"mt-2"}
                />

                <input
                  type='submit'
                  value='Envoyer'
                  className={"btn btn-primary btn-sm mt-5"}
                />
                <div>
                  <small>Attention cette action est irreversible.</small>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className={"container-header"}>
          Planning
          <Link
            className={"btn btn-primary btn-sm float-right"}
            to={"/calendar"}
          >
            Calendrier
          </Link>
        </div>

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {this.props.user &&
              (this.props.user.role === "cluster_manager" ||
                this.props.user.role === "admin") ? (
                <React.Fragment>
                  <a
                    href={"/sites"}
                    onClick={this.toggleOverlay}
                    className={"btn btn-primary btn-block mb-2"}
                  >
                    <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                    Nouveau planning
                  </a>
                </React.Fragment>
              ) : (
                ""
              )}

              <form
                onSubmit={this.exportResult}
                action='http://localhost/djezzy/public/api/export/csv'
                method='get'
              >
                <input
                  type='hidden'
                  name='token'
                  value={this.props.user.token}
                />
                <button
                  type='submit'
                  className={"btn btn-primary btn-block mb-3"}
                >
                  <FontAwesomeIcon icon={faFileExport} className={"mr-2"} />
                  Exporter le résultat
                </button>
              </form>

              <h4>Recherche</h4>

              <FiltersWrap update={this.updateFilter} session={this.props.user}>
                <FilterElement label={"Site name"} name={"site_name"} />
                <FilterElement label={"Code site"} name={"site_code"} />
                <FilterElement
                  type={"options"}
                  label={"Status"}
                  name={"site_status"}
                  options={this.props.filters.site_status}
                  defaultValue={{
                    label: "Active",
                    value: "active",
                  }}
                />

                <FilterElement
                  type={"options"}
                  label={"Type site"}
                  name={"cosite_type"}
                  options={this.props.filters.cosite_types}
                />

                <FilterElement
                  role={"admin"}
                  type={"options"}
                  label={"Cluster"}
                  name={"site_cluster"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.clusters,
                  ]}
                />

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Wilaya"}
                  name={"site_wilaya"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.wilayas,
                  ]}
                />

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Site Owner"}
                  name={"site_owner"}
                  options={this.props.filters.site_owners}
                />

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Manager"}
                  name={"site_manager"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.managers,
                  ]}
                />

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Agent"}
                  name={"site_agent"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.agents,
                  ]}
                />

                <FilterElement
                  type={"radio"}
                  name={"has_plan"}
                  options={[
                    { label: "Tous", value: "", default: true },
                    { label: "Non Planifié", value: false },
                    { label: "Planifié", value: true },
                  ]}
                />
              </FiltersWrap>

              <div className={"form-group"}>
                <label>Début</label>
                <input
                  onChange={(e) => this.updateFilter(e, "plan_start")}
                  type='date'
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>Fin</label>
                <input
                  onChange={(e) => this.updateFilter(e, "plan_end")}
                  type='date'
                  className={"form-control"}
                />
              </div>
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> sites trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className='col-md-12 ctable mb-3'>
                  <div className={"d-none d-sm-block"}>
                    <div className={"row head-titles ctable-row"}>
                      <div className={"col-md-1"}>Code</div>

                      <div className={"col-md-2"}>Site Name</div>

                      <div className={"col-md-2"}>Wilaya</div>

                      <div className={"col-md-1"}>Owner</div>

                      <div className={"col-md-2"}>PM Plan</div>

                      <div className={"col-md-2"}>Manager</div>

                      <div className={"col-md-2 task-details-col"}>
                        <Trans i18nKey='label_details' />
                      </div>
                    </div>
                  </div>

                  {this.state.sites.map((site, i) => (
                    <React.Fragment key={i}>
                      <div className={"row "}>
                        <div className={"col-md-12"}>
                          <div className={"row ctable-row"}>
                            <div className={"col-md-1"}>{site.site_code}</div>

                            <div className={"col-md-2"}>{site.site_name}</div>

                            <div className={"col-md-2"}>
                              {site.site_address_wilaya.toUpperCase()}
                            </div>

                            <div className={"col-md-1"}>{site.site_owner}</div>

                            {/*<div className={"col-md-2"}>
                              {site.site_next_pm}
                            </div>*/}

                            <div className={"col-md-2"}>
                              <span className={"task-plan-col"}>
                                {site.plan_dates
                                  ? site.plan_dates
                                      .split(",")
                                      .map((date) => <span>{date}</span>)
                                  : null}
                              </span>
                            </div>

                            <div className={"col-md-2 site-plan-col-date"}>
                              <span>{site.site_manager_username}</span>
                            </div>

                            {this.props.user.role === "cluster_manager" ||
                            this.props.user.role === "admin" ? (
                              <div className={"col-md-2 task-details-col"}>
                                {site.site_next_pm ? (
                                  <button
                                    onClick={() =>
                                      this.toggleDeleteOverlay(site.site_id)
                                    }
                                    className={"task-action-btn"}
                                  >
                                    Delete PM Plan
                                  </button>
                                ) : (
                                  ""
                                )}
                                <button
                                  onClick={() =>
                                    this.toggleOverlayPlan(site.site_id)
                                  }
                                  className={"task-action-btn"}
                                >
                                  Planifier PM
                                </button>

                                {/* <div>

                                    <a target="_blank" href={"https://www.google.com/maps/place/"+address.store_position.lat+","+address.store_position.lng} className={"btn btn-block btn-sm btn-primary"}>Map</a>
                                    <button  onClick={toggleHidden} data-row-index={i}  className={"btn btn-block btn-sm btn-primary"}>Photos </button>
                                    </div> */}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  {this.state.sites.length === 0 && (
                    <div className={"col-md-12 text-center my-3"}>
                      Aucun site trouvé
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanningList);
