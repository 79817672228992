import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import Select from "react-select";
import * as actions from "../../../Redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import { Trans } from "react-i18next";
import Pagination from "../../../Utils/Pagination";
import TeamEditOverlay from "../../User/TeamEditOverlay";
import { buildFilters } from "../../../Utils/Helpers";
import Constants from "../../../Utils/Constants";
import TeamRow from "./TeamRow";


const mapStateToProps = state => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAuth: user => dispatch(actions.setAuthAction(user)),
    isLoading: e => dispatch(actions.startLoading(e)),
    doneLoading: () => dispatch(actions.doneLoading()),
    setFlash: f => dispatch(actions.setFlash(f)),
    closeFlash: f => dispatch(actions.closeFlash(f))
  };
};

class TeamsList extends Component {

  constructor(props) {

    super(props);

    this.state = {
      users: [],
      filters: {
        status: "",
        name: "",
        cluster_manager: "",
        cluster: "",
        manager: "",
        site_owner: ""
      },
      tableIsLoading: true,
      limit: 15,
      pages: 1,
      current: 1,
      showOverlay: false,
      selectedUser: 0,
      renewDuration: 1,
      selected_user: {},
      showEditOverlay: false,
    };

    this.props.isLoading();

    this.loadTeams = this.loadTeams.bind(this);
    this.loadTeams = debounce(this.loadTeams, 300);
    this.selectPage = this.selectPage.bind(this);
    this.userDeleteBtn = this.userDeleteBtn.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }

  changeLimit(e) {

    this.props.isLoading();

    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTeams()
    );
  }

  selectPage(p) {

    this.props.isLoading();
    this.setState({ current: parseInt(p) });
    this.loadTeams(p);
  }

  componentDidUpdate(prev) {}

  getTimestamp() {

    var now = new Date();
    return parseInt(now.getTime() / 1000);
  }

  componentDidMount() {

    this.loadTeams();
  }

  toggleOverlay = () => {

    this.setState({ showOverlay: !this.state.showOverlay });
  };

  loadTeams = (p = this.state.current) => {

    let crits = buildFilters(this.state.filters) + "&limit=" + this.state.limit;

    api.get("/api/team/fetch?page=" + p + "&" + crits).then(response => {
      if (response.data.success) {
        this.setState(response.data);
      }
      this.props.doneLoading();
    });
  };

  userDeleteBtn = id => {

    this.setState({ selectedUser: id });
    this.toggleOverlay();
  };

  deleteUser = () => {

    const data = {
      user: this.props.user
    };

    this.toggleOverlay();
    this.props.isLoading();

    api.post("/api/team/delete/" + this.state.selectedUser, data).then(response => {
      if (response.data.success) {
        this.loadTeams();
        this.props.setFlash({
          active: true,
          message: "Team has been succesfully deleted.",
          classes: "success",
          style: {}
        });
      } else {
        this.props.doneLoading();
        this.props.setFlash({
          active: true,
          message: "Failed to delete team : " + response.data.error,
          classes: "warning",
          style: {}
        });
      }
    });
  };

  updateFilter = (e, name) => {

    const value = e.target ? e.target.value : e.value;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value
        }
      },
      () => {
        this.props.isLoading();
        this.loadTeams(1);
      }
    );
  };

  enableUser = userid => {

    this.props.isLoading({
      message: "Mise à jour en cours",
      animated: true
    });

    api.post("/api/user/enable/" + userid)
      .then(response => {
        if (response.data.success) {
          console.log("user disabled");
        }
      })
      .then(() => {
        this.props.doneLoading();
        this.loadTeams();
      });
  };

  disableUser = userid => {

    this.props.isLoading({
      message: "Mise à jour en cours",
      animated: true
    });

    api.post("/api/user/disable/" + userid)
      .then(response => {
        if (response.data.success) {
          console.log("user enabled");
        }
      })
      .then(() => {
        this.props.doneLoading();
        this.loadTeams();
      });
  };

  selectUser = user => {

    this.setState({
      selected_user: user,
      showEditOverlay: true
    });
  };

  toggleEditOverlay = () => this.setState({ showEditOverlay: !this.state.showEditOverlay });

  exportUsers = e => {

    e.preventDefault();
    e.target.action = Constants.apiURL + "/api/team/fetch?export=true&limit=-1&" + buildFilters(this.state.filters);
    e.target.submit();
    // console.log();
  };

  render() {

    const toggleOverlay = this.toggleOverlay;
    const showOverlay = this.state.showOverlay;

    return (
      <div>
        <TeamEditOverlay
          reload={this.loadTeams}
          role={"agent"}
          open={this.state.showEditOverlay}
          selected_user={this.state.selected_user}
          toggleEditOverlay={this.toggleEditOverlay}
          {...this.props}
        />

        {!this.props.auth ? <Redirect to="/login" /> : ""}

        <div className={showOverlay ? "ui-overlay open" : "ui-overlay"}>
          <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
            <button
              onClick={e => {
                toggleOverlay(e);
              }}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            Are you sure you want to delete this team?
            <br />
            <div className={"delete-buttons"}>
              <button
                onClick={e => {
                  toggleOverlay(e);
                }}
                className={"btn btn-info"}
              >
                No
              </button>
              <button onClick={this.deleteUser} className={"btn btn-danger ml-2"}>
                Delete
              </button>
            </div>
          </div>
        </div>

        <div className={"container-header"}>Site keepers</div>

        <div className={"clear"} />

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {this.props.user.role !== "agent" && (
                <>
                  <Link to={"/teams/new"} className={"btn btn-primary btn-block my-3"}>
                    <b>
                      <Trans i18nKey="button_new_team" />
                    </b>
                  </Link>
                  <form action="" target={"_blank"} method="post" onSubmit={this.exportUsers}>
                    <input type="hidden" name={"token"} value={this.props.user.token} />
                    <button type="submit" className={"btn btn-primary btn-block my-3"}>
                      <b>Exporter</b>
                    </button>
                  </form>
                </>
              )}

              <h4>Recherche</h4>

              {/*<div className={"form-group"}>
                <label>Matricule</label>
                <input
                  onChange={e => this.updateFilter(e, "matricule")}
                  name="matricule"
                  type="text"
                  className={"form-control"}
                />
              </div>*/}

              <div className={"form-group"}>
                <label>
                  <Trans i18nKey="label_name" />
                </label>
                <input
                  onChange={e => this.updateFilter(e, "name")}
                  value={this.state.filters.name}
                  name="name"
                  type="text"
                  className={"form-control"}
                />
              </div>

              {/*<div className={"form-group"}>
                <label>Cluster</label>
                <Select
                  onChange={v => this.updateFilter(v, "cluster")}
                  options={this.props.filters.clusters}
                  classNamePrefix={"custom-select"}
                />
              </div>

              <div className={"form-group"}>
                <label>Owner Type</label>
                <Select
                  onChange={v => this.updateFilter(v, "site_owner")}
                  options={[{ label: "Tous", value: "" }, ...this.props.filters.site_owners]}
                  classNamePrefix={"custom-select"}
                />
              </div>

              <div className={"form-group"}>
                <label>Cluster Manager</label>
                <Select
                  onChange={v => this.updateFilter(v, "cluster_manager")}
                  options={[{ label: "Tous", value: "" }, ...this.props.filters.cluster_managers]}
                  classNamePrefix={"custom-select"}
                />
              </div>*/}

              <div className={"form-group"}>
                <label>Manager</label>
                <Select
                  onChange={v => this.updateFilter(v, "manager")}
                  options={[{ label: "Tous", value: "" }, ...this.props.filters.managers]}
                  classNamePrefix={"custom-select"}
                />
              </div>

              {/*<div className={"form-group"}>
                <label>Connexion</label>
                <Select
                  onChange={v => this.updateFilter(v, "last_online")}
                  options={this.props.filters.connectionInterval}
                  classNamePrefix={"custom-select"}
                />
              </div>

              <div className={"form-group"}>
                <label>Account Status</label>
                <Select
                  onChange={v => this.updateFilter(v, "status")}
                  options={this.props.filters.account_status}
                  classNamePrefix={"custom-select"}
                />
              </div>

              <div className={"form-group"}>
                <label>Task Type</label>
                <Select
                  onChange={v => this.updateFilter(v, "task_type")}
                  options={this.props.filters.task_types}
                  classNamePrefix={"custom-select"}
                />
              </div>*/}

              <div className={"form-group"}>
                <label>Wilaya</label>
                <Select
                  onChange={v => this.updateFilter(v, "wilaya")}
                  options={this.props.filters.wilayas}
                  classNamePrefix={"custom-select"}
                />
              </div>

              <div className={"form-group"}>
                <label>Owner</label>
                <Select
                  onChange={v => this.updateFilter(v, "owner")}
                  options={this.props.filters.services}
                  classNamePrefix={"custom-select"}
                />
              </div>

              {/*<div className={"form-group"}>
                <label>Wilaya</label>
                <Select
                  onChange={v => this.updateFilter(v, "wilaya")}
                  options={this.props.filters.wilayas}
                  classNamePrefix={"custom-select"}
                />
            </div>*/}
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> Groupes trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <div className="col-md-12 ctable">
              <div className={"d-none d-sm-block"}>
                <div className={"row head-titles ctable-row"}>
                  <div className={"col-md-2"}>Team</div>
                  <div className={"col-md-2"}>Email</div>
                  <div className={"col-md-2"}>Manager</div>
                  {/*<div className={"col-md-1"}>Task Type</div>
                  <div className={"col-md-2"}>
                    <Trans i18nKey="user_phone" />
                  </div>
                  <div className={"col-md-2"}>Derniere connexion</div>
                  <div className={"col-md-2"}>Sites</div>*/}
                  <div className={"col-md-2"}>Wilaya</div>
                  <div className={"col-md-2"}>Owner</div>
                  <div className={"col-md-2"} />
                </div>
              </div>

              {this.state.users.map((user, j) => (
                <TeamRow
                  user={user}
                  key={j}
                  selectUser={this.selectUser}
                  disableUser={this.disableUser}
                  userDeleteBtn={this.userDeleteBtn}
                  enableUser={this.enableUser}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsList);
